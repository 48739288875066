import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate", "customDateRange"];

  connect() {
    console.log("ReportFilterController connected");
    this.toggleCustomDateRange();
  }

  toggleCustomDateRange() {
    const selectedValue = document.querySelector(
      'input[name="report_filter[filter_type]"]:checked',
    ).value;
    console.log(selectedValue);
    if (selectedValue === "custom_date_range") {
      this.customDateRangeTarget.classList.remove("d-none");
    } else {
      this.customDateRangeTarget.classList.add("d-none");
    }
  }
}
