import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["colorsGroup"];

  connect() {
    console.log("Flash controller connected");
    console.log(this.colorsGroupTarget); // Check if this logs the correct element
  }

  toggle(event) {
    const flashEnabled = event.target.checked;
    const colorGroup = this.colorsGroupTarget;

    // Check if the 8th option is already present
    const eighthOption = colorGroup.querySelector('input[value="8"]');

    if (flashEnabled && eighthOption) {
      // Remove the 8th color option if Flash is enabled
      eighthOption.closest("label").remove();
    } else if (!flashEnabled && !eighthOption) {
      // Re-add the 8th color option if Flash is disabled and it was removed
      const newOption = document.createElement("label");
      newOption.className = "form-selectgroup-item";
      newOption.innerHTML = `
        <input type="radio"
               name="screen_print[colors]"
               value="8"
               class="form-selectgroup-input" />
        <span class="form-selectgroup-label">8</span>
      `;
      colorGroup.appendChild(newOption);
    }
  }
}
